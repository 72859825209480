

.App{
  text-align: center;
  background-image: url('./backgroundimg.jpg');
  background-position: center;
  background-repeat: repeat;
  font-family: 'Montserrat';
  color: rgba(118, 118, 115);
  
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
  user-select: none; 


  p{
    padding:0;
  }
  .title {
    font-size: 4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-weight: 500;
    position: relative;
    text-align: center;
  
    @media screen and (max-width: 768px) {
      font-size: 1.9rem; /* Ajuste o tamanho conforme necessário */
    }
  
    
  }

  .text {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-weight: 400;
    margin-left: 10%;
    margin-right: 10%;
  
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  .headervideo {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    object-position: center;
    position: relative;
    mask: linear-gradient(to top, rgba(5, 0, 3, 0) 0%, rgba(0, 0, 0, 1) 35%);
  }
  
  
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  
  .App-logo {
    width: 30vw;
    height: auto;
    margin-bottom: 20px;
  }
  
  .buttons {
    @media screen and (max-width: 478px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      
    }
    
  }
  
  .button {
    background-color: #FF5D73;
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    border-radius: 5px;
    margin-left: 30px;
    width: fit-content;
    height: auto;
    font-size: 120%;

    @media screen and (max-width: 422px) {
      margin-left: 0;
      margin-bottom: 5px;
      
    }
  }

  .cardservices{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
  .swiper-pagination{
    position:static;
  }

  .swiper-wrapper{
    justify-content: none;
  }

  .imgtable{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: relative;
  }

  .inputbox{

    justify-content: center;
    align-items: center;

  }

  .profile-icon {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 9999; /* Garante que o ícone fique sobreposto a outros elementos */
  }
  
  /* Estilos para o ícone de perfil */
  .profile-icon img {
    width: 40px; /* Defina o tamanho do ícone conforme necessário */
    height: 40px;
    border-radius: 50%; /* Para criar um formato de círculo para o ícone */
    cursor: pointer; /* Adicione este estilo se o ícone for clicável */
  }
  
 

  
  }